import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

const Accessibility = ({ data }) => (
  <Layout>
    <SEO
      title="Accessibility"
      keywords="ribsusabbq, ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me"
    />
    <PageTitle title="Accessibility" />
    <section id="accessibility">
      <div className="container">
        <div className="row">
          <div className="privacy-content">
            <div className="text-center">
              <h1 className="sans">Accessibility Policy & Statement</h1>
              <div className="d-flex justify-content-center">
                <p className="sans p-update">
                  Last updated: {data.site.siteMetadata.date}
                </p>
              </div>
            </div>
            <div className="p-content">
              <div className="d-flex justify-content-left">
                <h3 className="sans">General</h3>
              </div>
              <p>
                <b>{data.site.siteMetadata.domain} </b> strives to ensure that
                its services are accessible to people with disabilities.{" "}
                <b>{data.site.siteMetadata.domain} </b> has invested a
                significant amount of resources to help ensure that its website
                is made easier to use and more accessible for people with
                disabilities, with the strong belief that every person has the
                right to live with dignity, equality, comfort and independence.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  Accessibility on <b>{data.site.siteMetadata.domain} </b>
                </h3>
              </div>
              <p>
                <b>{data.site.siteMetadata.domain} </b> makes available the
                UserWay Website Accessibility Widget that is powered by a
                dedicated accessibility server. The software allows{" "}
                <b>{data.site.siteMetadata.domain} </b> to improve its
                compliance with the Web Content Accessibility Guidelines (WCAG
                2.1).
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Enabling the Accessibility Menu</h3>
              </div>
              <p>
                The <b>{data.site.siteMetadata.domain} </b> accessibility menu
                can be enabled by clicking the accessibility menu icon that
                appears on the corner of the page. After triggering the
                accessibility menu, please wait a moment for the accessibility
                menu to load in its entirety.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Disclaimer</h3>
              </div>
              <p>
                <b>{data.site.siteMetadata.domain} </b> continues its efforts to
                constantly improve the accessibility of its site and services in
                the belief that it is our collective moral obligation to allow
                seamless, accessible and unhindered use also for those of us
                with disabilities. Despite our efforts to make all pages and
                content on <b>{data.site.siteMetadata.domain} </b> fully
                accessible, some content may not have yet been fully adapted to
                the strictest accessibility standards. This may be a result of
                not having found or identified the most appropriate
                technological solution.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Here For You</h3>
              </div>
              <p>
                If you are experiencing difficulty with any content on{" "}
                <b>{data.site.siteMetadata.domain} </b> or require assistance
                with any part of our site, please contact us during normal
                business hours as detailed below and we will be happy to assist.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Contact Us</h3>
              </div>
              <p>
                If you wish to report an accessibility issue, have any questions
                or need assistance, please contact{" "}
                <b>{data.site.siteMetadata.domain} </b> Customer Support as
                follows: <br /> <br />
                Email:
                <a
                  className="con-hover"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  {data.site.siteMetadata.email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Accessibility

export const query = graphql`
  {
    site {
      siteMetadata {
        date
        email
        domain
      }
    }
  }
`
